.sales-sub-text{
    font-size:10px
}


.payment-container-left{
    background-color: white;
    margin-right: -10px;
    padding-top: 10px;
    padding-right: 10px;
    min-height: 82vh;
}

.payment-container-right{
    background-color: white;
    padding-top: 10px;
    /* padding-left:10px;
    padding-right: 10px; */
    min-height: 82vh;
}

.payment-container-section {
    min-height: 60vh; /* will cover the 100% of viewport */
    overflow: hidden;
    display: block;
    position: relative;
    padding-bottom: 100px; /* height of your footer */
   }

   .footer1 {
    position: absolute;
    bottom: 0;
    width: 100%;
    height:50px
   }

   .pay-btn{
       height:60px;
       padding:10px
   }

   .pay-btn small{
       margin-left: 20px
   }

  .pad{
       padding-top:10px;
       padding-right:10px;
       margin-top:0px
   }

   .product-tab{
    min-height: 77vh; 
    background-color:white;
    margin-top:0px
   }

   .item-list{
    height: 77vh; 
    background-color:white;
   }
   
   .item-list-scroll{
    height: 66vh; 
    overflow-y: scroll;
    overflow-x:hidden;
   }

   .line-item-list-scroll-long{
    height: 60vh; 
    overflow-y: scroll;
    overflow-x:hidden;
   }

   .line-item-list-scroll-short{
    height: 50vh; 
    overflow-y: scroll;
    overflow-x:hidden;
   }

.scroll2::-webkit-scrollbar {
  width: 5px;
}
 
.scroll2::-webkit-scrollbar-thumb {
  /* background: #666;  */
  background:silver;  
}

   .payment-section{
    min-height: 80vh; 
    background-color:white;
    margin-top:0px
   }

   .product-selection-grid {
    /* display: flex;
    flex-wrap: wrap; */
    overflow-y: scroll;
    height:66vh;
    /* padding-top: 5px; */
    /* padding-left:5px */
    /* max-width: 1000px; */
    /* margin: 0 auto; */
   }
   .product-selection-btn{
    padding:5px;
    width:21%;
    height:100px;
    margin:5px
   }

   .category-selection-btn{
    padding:5px;
    width:94%;
    height:100px;
    margin:5px
   }

   .category-selection-btn-selected {
    padding: 5px;
    width: 94%;
    height: 100px;
    margin: 5px;
    border: solid 2px #00a65a;
    background-color: #00a65a;
    color: white
}
.category-selection-btn-selected:hover {
  padding: 5px;
    width: 94%;
    height: 100px;
    margin: 5px;
    border: solid 2px green;
    background-color: green;
    color: white;
  
}

   .product-select-btn-text1{
    color: #000;
    display: -webkit-box;
    margin: 4px;
    font-size: 14px;
    height: 3.6em;
    white-space: normal;
    font-weight: normal;
    text-align: left;
    line-height: 1.2em;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.product-select-btn-text-full1{
    color: #000;
    display: -webkit-box;
    margin: 4px;
   
    height: 3.6em;
    white-space: normal;
    font-weight: normal;
    text-align: left;
    line-height: 1.2em;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-select-btn-text-full-product-name{
    font-size: 17px;
    margin: 0px;
    white-space: normal;
    height:70px;
    overflow: hidden;
    text-overflow: ellipsis;
}
.product-select-btn-text-full-product-name1{
    height:70px;
    font-size: 16px;

    /* color: #000; */
    display: -webkit-box;
    margin: 0px;
   
    /* height: 3.6em; */
    white-space: normal;
    font-weight: normal;
    text-align: left;
    line-height: 1.2em;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.product-select-btn-text-full-product-price1{
    height:30px;
    font-size: 12px;
    margin: 0px;
    white-space: normal;
    font-weight: normal;
    text-align: left;
    line-height: 1.2em;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-select-btn-text-name1{
    /* height:95px; */
    font-size: 16px;
    /* color: #000; */
    display: -webkit-box;
    margin: 4px;
    /* height: 3.6em; */
    white-space: normal;
    font-weight: normal;
    text-align: left;
    line-height: 1.2em;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}

.product-select-btn-text-price1{
    height:40px;
    font-size: 12px;
    margin: 4px;
    white-space: normal;
    font-weight: normal;
    text-align: left;
    line-height: 1.2em;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
   .photo {
    height:60px
   }

